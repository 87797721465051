import React, { useEffect, useState } from "react";
import { createClient } from "../../utils/supabase/client";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../../components/Snackbar/Snackbar";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useAuth } from "../../contexts/Auth";

function AuthPage() {
  const buttonState = localStorage.getItem("buttonState");

  //Sign-in form state
  const [isSignUp, setIsSignUp] = useState(
    buttonState === "signup" ? true : false
  );
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const supabase = createClient();

  //Snackbar state
  const { showSnackbar, message, triggerSnackbar } = useSnackbar();

  // Navigation
  const navigate = useNavigate();

  // Function to allow user to sign up (create new account) by calling serverless function
  const signUp = async () => {
    setLoading(false);

    // Check if user entered all required fields
    if (!email || !password || !confirmPassword || !firstName || !lastName) {
      triggerSnackbar(
        "Please fill out all fields, including first name, last name, email, and password.",
        2000
      );
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      triggerSnackbar("Passwords do not match!", 2000);
      return;
    }

    setLoading(true);

    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          fullName: `${firstName} ${lastName}`,
        },
      },
    });

    //show alert if there is an error signing up
    if (error) {
      setLoading(false);
      triggerSnackbar(error.message, 2000);
      return;
    }

    //successful sign up
    triggerSnackbar("Account created successfully, you can now log in", 2000);
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setConfirmPassword("");
    setLoading(false);
    setIsSignUp(false); //change to log-in section
  };

  //Function to allow the user to sign in
  const signIn = async () => {
    //check if user entered both an email and a password
    if (!email || !password) {
      triggerSnackbar("Please enter an email and a password", 2000);
      return;
    }

    setLoading(true);

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    //show alert if there is a log in error
    if (error) {
      setLoading(false);
      triggerSnackbar(error.message, 2000);
      return;
    }

    //log the log in event in the database
    setLoading(false);
    navigate("/dashboard");
  };

  const handleAuth = async (e) => {
    e.preventDefault();

    if (isSignUp) {
      await signUp();
    } else {
      await signIn();
    }
  };

  useEffect(() => {
    //If user is already logged in, redirect them directly to the dashboard page
    setLoading(true);
    if (user) navigate("/dashboard");
    setLoading(false);
  }, [navigate, user]);

  if (loading) return <LoadingIndicator />;

  return (
    <div
      className="min-h-screen flex justify-center items-center sm:px-6 lg:px-8"
      data-theme="dark"
    >
      <div className="absolute top-0 left-0">
        <button onClick={() => navigate("/")}>
          <img
            className="w-64 md:w-80 lg:w-64 p-5"
            src={logo}
            alt="company logo"
          ></img>
        </button>
      </div>

      <div
        className={`max-w-xl md:max-w-2xl lg:max-w-4xl h-[${
          isSignUp === true ? 600 : 400
        }px] w-full md:w-3/4 lg:w-1/2 xl:w-1/3 space-y-8 p-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg shadow-xl mt-6 mx-auto`}
      >
        <h2 className="text-center text-3xl md:text-4xl lg:text-5xl font-extrabold text-black">
          {isSignUp ? "Sign Up" : "Sign In"}
        </h2>
        <div className="flex justify-center my-4 mt-5 pt-3 gap-2">
          <button
            className={`w-1/2 py-2 text-sm md:text-md lg:text-lg font-medium rounded-l-lg ${
              isSignUp
                ? "bg-white bg-opacity-10 text-black border-black"
                : "bg-indigo-500 text-gray-100"
            }`}
            onClick={() => setIsSignUp(false)}
          >
            Sign In
          </button>
          <button
            className={`w-1/2 py-2 text-sm md:text-md lg:text-lg font-medium rounded-r-lg ${
              isSignUp
                ? "bg-indigo-500 text-gray-100"
                : "bg-white bg-opacity-10 text-black"
            }`}
            onClick={() => setIsSignUp(true)}
          >
            Sign Up
          </button>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleAuth}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="flex justify-between">
              <div>
                <label htmlFor="firstName" className="sr-only">
                  First name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  hidden={isSignUp === true ? false : true}
                  required={isSignUp === true ? true : false}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm md:text-md lg:text-lg bg-white"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="lastName" className="sr-only">
                  Last name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  hidden={isSignUp === true ? false : true}
                  required={isSignUp === true ? true : false}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm md:text-md lg:text-lg bg-white"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm md:text-md lg:text-lg bg-white"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm md:text-md lg:text-lg bg-white"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Toggle visibility icon */}
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                )}
              </span>
            </div>
            {isSignUp && (
              <div className="relative">
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm md:text-md lg:text-lg bg-white"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {/* Toggle visibility icon */}
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </span>
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm md:text-md lg:text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isSignUp ? "Sign Up" : "Sign In"}
            </button>
          </div>
        </form>
        <Snackbar isOpen={showSnackbar} message={message} />
      </div>
    </div>
  );
}

export default AuthPage;
