import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contact" style={{ backgroundColor: "black", padding: "20px" }}>
      <div className="text-center" style={{ color: "white" }}>
        © 2024 All rights reserved. swiftlead - LinkedIn automation tool.
        <NavLink to={"/privacy-policy"} style={{ color: "#5271ff" }}>
          {""} Privacy
        </NavLink>{" "}
        and{" "}
        <NavLink to={"/terms-and-conditions"} style={{ color: "#5271ff" }}>
          Terms
        </NavLink>{" "}
        .
      </div>
    </footer>
  );
};

export default Footer;
