import React from "react";
import Header from "../../components/Header/Header";
import Headline from "../Headline/Headline";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="mt-14">
      <Header />
      <Headline text="Privacy Policy" />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h2 className="text-xl font-bold mt-6 mb-2">Article 1: Introduction</h2>
        <p className="mb-4">
          <strong>Commitment to Privacy</strong> At swiftlead LLC (“swiftlead,”
          “we,” “us,” or “our”), safeguarding the privacy of our users (“User”
          or “you”) is a cornerstone of our values. This Privacy Policy
          delineates the principles and practices that swiftlead adheres to in
          collecting, using, maintaining, and disclosing personal and
          non-personal information from users of the swiftlead.io website
          (“Site”) and our LinkedIn automation tool (“Tool”).
        </p>
        <p className="mb-4">
          <strong>Scope of Policy</strong> This policy governs all interactions
          where Users engage with swiftlead’s services, including, but not
          limited to, the use of our Site, Tool, and any other products or
          services provided by swiftlead. It extends to all platforms and
          devices where our services are accessed or used, ensuring a consistent
          commitment to your privacy across all interfaces.
        </p>
        <p className="mb-4">
          <strong>Legal Foundations</strong> The practices outlined in this
          Privacy Policy are compiled in compliance with applicable privacy laws
          and regulations governing digital information security. By using our
          Site and Tool, you consent to the data practices described in this
          document. Should you disagree with any terms provided herein, you are
          advised to discontinue use of our services immediately.
        </p>
        <p className="mb-4">
          <strong>Updates to the Policy</strong> swiftlead reserves the right to
          amend this Privacy Policy at any time. Such amendments become
          effective immediately upon posting on our Site. We will endeavor to
          provide reasonable notice of significant changes via our Site or
          through direct communication channels. Your continued use of our
          services after any changes to this Privacy Policy will constitute your
          acceptance of such changes.
        </p>
        <p className="mb-4">
          <strong>Accessibility of the Policy</strong> This Privacy Policy is
          made available on the swiftlead website, accessible from the homepage
          and at each point where personally identifiable information may be
          requested. This ensures transparency and easy accessibility, helping
          you to make informed decisions about the management of your personal
          information while using our services.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 2: Information Collection
        </h2>
        <p className="mb-4">
          <strong>Personal Identification Information</strong> We may collect
          personal identification information from Users in various ways,
          including, but not limited to, when Users visit our Site, register on
          the Site, place an order, subscribe to a newsletter, fill out a form,
          and in connection with other activities, services, features, or
          resources we make available. Users may be asked for, as appropriate,
          name, email address, mailing address, phone number, and payment
          information. Users can always visit our Site anonymously. We will
          collect personal identification information from Users only if they
          voluntarily submit such information to us.
        </p>
        <p className="mb-4">
          <strong>Non-Personal Identification Information</strong>We may collect
          non-personal identification information about Users whenever they
          interact with our Site. This may include the browser name, the type of
          computer, and technical information about Users’ means of connection
          to our Site, such as the operating system and the Internet service
          providers utilized, and other similar information.
        </p>
        <p className="mb-4">
          <strong>Web Browser Cookies</strong> Our Site may use “cookies” to
          enhance User experience. User’s web browser places cookies on their
          hard drive for record-keeping purposes and sometimes to track
          information about them. Users may choose to set their web browser to
          refuse cookies or to alert you when cookies are being sent. If they do
          so, note that some parts of the Site may not function properly.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 3: Use of Collected Information
        </h2>
        <p className="mb-4">
          <strong>Purpose of Information Collection</strong>swiftlead collects
          and uses Users’ personal information primarily to enhance and
          personalize the user experience and improve the services offered. The
          specific purposes for which your personal information is processed are
          outlined below:
        </p>
        <p className="mb-4">
          <strong>To Improve Customer Service</strong> Your information helps us
          respond more effectively to your customer service requests and support
          needs. By understanding your history and preferences, we can address
          your concerns more quickly and efficiently, ensuring a smoother
          service experience.
        </p>
        <p className="mb-4">
          <strong>To Personalize User Experience</strong> We may use information
          in the aggregate to understand how our Users as a group use the
          services and resources provided on our Site and Tool. This statistical
          analysis helps us tailor our offerings to better align with your needs
          and preferences, enhancing your overall experience with our services.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 4: Data Protection Measures
        </h2>
        <p className="mb-4">
          <strong>Comprehensive Security Practices</strong> swiftlead employs
          robust data collection, storage, and processing practices, along with
          security protocols designed to shield against unauthorized access,
          alteration, disclosure, or destruction of your personal information,
          login credentials, transaction details, and all other data stored on
          our platforms. These include:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>1.</strong>Physical Security Measures: Secure facilities
            that prevent unauthorized access to our physical infrastructure
            where data is stored.
          </li>
          <li>
            <strong>2.</strong> Managerial Safeguards: Training and policies
            that ensure only authorized staff members have access to personal
            information, based on their roles and necessity to engage with the
            data.
          </li>
          <li>
            <strong>3.</strong> Regular Security Reviews: Our security policies
            and procedures are periodically reviewed and updated as necessary to
            meet the evolving threats and ensure the integrity and safety of
            your information.
          </li>
        </ul>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 5: Sharing and Disclosure of Personal Information
        </h2>
        <p className="mb-4">
          <strong>Restrictions on Sharing</strong> swiftlead is committed to
          protecting your personal identification information. We do not sell,
          trade, or rent this information to others. Our use of your information
          is strictly for the purposes described in this Privacy Policy.
        </p>
        <p className="mb-4">
          <strong>Aggregated Information</strong> We may share aggregated
          demographic information not linked to any personal identification
          information with our business partners, trusted affiliates, and
          advertisers. This is done for the purposes of conducting business
          analysis, advertising, and other promotional activities. By sharing
          aggregated data, we ensure that no personally identifiable information
          is disclosed.
        </p>
        <p className="mb-4">
          <strong>Engagement with Third Parties</strong> When we engage third
          parties to assist in our operations, such as marketing, analytics, or
          customer service, we ensure that these parties are bound by
          confidentiality agreements and do not have permission to use your
          information for any other purposes other than to perform the services
          we have contracted them for.
        </p>
        <p className="mb-4">
          <strong>Legal Compliance and Safety</strong> We may disclose personal
          information when required by law or in the good-faith belief that such
          action is necessary to comply with state and federal laws or respond
          to a court order, subpoena, or search warrant. swiftlead may also
          disclose personal information to protect the safety of our users, the
          public, or swiftlead itself.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 6: Interaction with Third-Party Websites
        </h2>
        <p className="mb-4">
          <strong>Links to External Sites</strong> swiftlead’s Site and services
          may contain links to external websites operated by our partners,
          suppliers, advertisers, sponsors, licensors, or other third parties
          not directly affiliated with us. While we strive to provide only
          quality links to useful and ethical websites, we have no control over
          the content and nature of these external sites. The inclusion of any
          links does not necessarily imply a recommendation or endorse the views
          expressed within them.
        </p>
        <p className="mb-4">
          <strong>No Control Over Third Parties</strong>Please be aware that
          these third-party sites have their own privacy policies and terms of
          service. When leaving our Site to visit another, we encourage you to
          read the privacy statements of each site that collects personally
          identifiable information. swiftlead is not responsible for the privacy
          practices or the content of such websites.
        </p>
        <p className="mb-4">
          <strong>Constant Changes</strong> The content and links on these
          third-party sites may be constantly changing and may lead to resources
          that some people may find inappropriate or offensive. swiftlead does
          not warrant the accuracy, relevance, timeliness, or completeness of
          any information on these external websites.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 7: Modifications to the Privacy Policy
        </h2>
        <p className="mb-4">
          <strong>Right to Modify</strong> swiftlead reserves the right to
          update or change our Privacy Policy at any time. Changes to this
          policy will be posted on our Site with an updated revision date and,
          when appropriate, notified to you by email. Such modifications become
          effective immediately upon their posting on the website.
        </p>
        <p className="mb-4">
          <strong>Review and Acknowledgement</strong> We encourage you to
          periodically review this Privacy Policy to stay informed about how we
          are protecting your information. By continuing to use our Site and
          services after any modifications to this Privacy Policy, you
          acknowledge and agree to the revised policy. It is your responsibility
          to review this Privacy Policy periodically and remain informed about
          any changes to it, thus ensuring you understand how any personal
          information you provide will be used.
        </p>
        <p className="mb-4">
          <strong>Notifications</strong> In the event of significant changes to
          our Privacy Policy, we will endeavor to provide more prominent notice
          which may include email notification or notification on our Site. This
          ensures that you are aware of what information we collect, how it is
          used, and under what circumstances, if any, it is disclosed.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 8: Your Acceptance of These Terms
        </h2>
        <p className="mb-4">
          <strong>Consent to Terms</strong> By accessing and using the
          swiftlead.io website (“Site”) and/or our LinkedIn automation tool
          (“Tool”), you, the user, hereby acknowledge and agree to be bound by
          this Privacy Policy. Your use of the Site and/or Tool constitutes
          irrevocable acceptance of these terms at the time of your access. If
          at any point you do not agree with any part of the current or future
          Privacy Policy, you are instructed to immediately cease all use of
          this Site.
        </p>
        <p className="mb-4">
          <strong>Implied Acceptance</strong>Continued use of the Site and/or
          Tool following any updates or changes to this Privacy Policy will be
          considered as your conclusive acceptance of such changes. It is your
          responsibility to regularly review this Privacy Policy and any
          amendments thereto.
        </p>
        <p className="mb-4">
          <strong>Legal Binding</strong> Your acceptance of these terms acts as
          a legally binding agreement between you and swiftlead LLC regarding
          your use of the Site, Tool, and any services provided. This acceptance
          is considered valid and retained for all legal purposes as evidence of
          your consent and agreement to adhere to all terms herein.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 9: Contacting Us
        </h2>

        <p className="mb-4">
          <strong>Communication Channels</strong>For any questions, concerns, or
          comments about this Privacy Policy or any other inquiries related to
          your experience at swiftlead, please direct your communication to the
          following email:
          <a href="mailto:support@swiftlead.io" className="text-blue-500">
            support@swiftlead.io
          </a>
        </p>

        <p className="mb-4">
          <strong>Response Policy</strong> We commit to responding to your
          inquiries as quickly as possible. Our dedicated team will address your
          concerns and provide the necessary support related to any aspect of
          our services or your experiences with our Site and Tool.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
