/**
 * Manage the state of the snackbar and display / hide it in a scalable way
 */
import { useState } from "react";

export const useSnackbar = () => {
  //note that this hook keeps internal state
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const triggerSnackbar = (msg, duration = 2000) => {
    setMessage(msg);
    setShowSnackbar(true);
    setTimeout(() => setShowSnackbar(false), duration);
  };

  return { showSnackbar, message, triggerSnackbar };
};