import React, { useContext, useState, useEffect } from "react";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { createClient } from "../utils/supabase/client";

//global state with supabase instance and user variables
const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(false);

  const supabase = createClient();

  useEffect(() => {
    async function checkUserSession() {
      setLoading(true);

      try {
        const session = await supabase.auth.getSession();

        if (session.data.session) {
          const user = session.data.session.user;
          setUser(user);
        } else {
          setUser(null);
          setUserProfile(null);
        }
      } catch (error) {
        // console.error("Error fetching session or profile:", error);
      } finally {
        setLoading(false);
      }
    }

    checkUserSession();

    const {
      data: { listener },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        checkUserSession(); // Re-use the same function to handle session changes
      } else {
        setLoading(false); // Ensure loading is set to false if session is null
      }
    });

    return () => listener?.unsubscribe();
  }, [supabase]);

  const state = {
    user,
    loading,
    userProfile,
    logout: () => setUser(null),
  };

  return (
    <AuthContext.Provider value={state}>
      {loading ? <LoadingIndicator /> : children}
    </AuthContext.Provider>
  );
}

//children can use this function to access context properties
export function useAuth() {
  return useContext(AuthContext);
}
