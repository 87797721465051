import React, { useState } from "react";

const Tooltip = ({ params }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="relative">
      <span
        className="cursor-pointer"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={toggleTooltip}
      >
        {params.length > 18 ? `${params.substring(0, 12)}...` : params}
      </span>
      {showTooltip && (
        <div className="bg-gray-800 text-white text-sm p-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2">
          {params}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
