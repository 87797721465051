import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../contexts/Auth';
import NotFound from './NotFound';

//pages wrapped in this route component are only displayed if the user is logged in
export default function PrivateRoute({ component: Component, ...rest}){
    const { user } = useAuth();

    return (
        user ? <Outlet /> : <NotFound />
    );
}