import { useState } from "react";
import { createClient } from "../../utils/supabase/client";
import { useNavigate } from "react-router";
import Snackbar from "../../components/Snackbar/Snackbar";
import { useSnackbar } from "../../hooks/useSnackbar";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { getStripe } from "../../utils/stripe/client";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
const { confirm } = Modal;

export default function Pricing({ user, products, subscription }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar, message, triggerSnackbar } = useSnackbar();

  const supabase = createClient();

  const handleStripeCheckout = async (price) => {
    setIsLoading(true);

    try {
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;
      const resp = await fetch("/api/stripe/buyPlan", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ price }),
      });

      const json = await resp.json();

      if (resp.ok) {
        const { sessionId } = json;
        const stripe = await getStripe();
        await stripe.redirectToCheckout({ sessionId });
      } else {
        triggerSnackbar(json.error);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error in handleStripeCheckout:", err);
      triggerSnackbar("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTrialSubscription = async (price) => {
    setIsLoading(true);

    try {
      const userToken = (await supabase.auth.getSession()).data.session
        .access_token;

      const resp = await fetch("/api/stripe/trialPlan", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ price }),
      });

      const json = await resp.json();

      if (resp.ok) {
        triggerSnackbar("Trial subscription created successfully!");

        // Redirect or refresh after showing the message
        setTimeout(() => {
          // Example: refresh window
          window.location.reload();
        }, 2000);
      } else {
        triggerSnackbar(json.error || "Failed to start the trial.");
      }
    } catch (err) {
      console.error("Error in handleTrialSubscription:", err);
      triggerSnackbar("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem("supabase.auth.token");
    localStorage.removeItem("supabase.auth.expires_at");
    window.location.href = window.location.origin;
  };

  // Function to show the confirmation modal
  const showPromiseConfirm = (operation) => {
    confirm({
      title: "Do you want to proceed with this subscription?",
      icon: <ExclamationCircleFilled />,
      content: "Click OK to confirm your action.",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              operation(); // Call the operation passed to the modal
              resolve(); // Resolve the promise on success
            } catch (error) {
              console.error("Operation failed:", error);
              reject(); // Reject the promise on failure
            }
          }, 1000); // Simulate a delay for the operation
        }).catch(() => console.log("Oops! Something went wrong."));
      },
      onCancel() {
        console.log("Operation canceled.");
      },
    });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!products.length) {
    return (
      <>
        <section className="h-screen" data-theme="dark">
          <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="sm:flex sm:flex-col sm:align-center"></div>
            <p className="text-4xl font-extrabold text-white sm:text-center sm:text-6xl">
              No subscriptions available.
            </p>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section data-theme="dark">
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="md:flex md:gap-4">
              {/* main pack */}
              <div className="mt-2 space-y-4 sm:mt-16 sm:space-y-0 flex flex-wrap justify-center gap-6 w-full">
                {products.map((product) => {
                  const price = product?.prices?.find(
                    (price) => price.interval === "month"
                  );

                  const priceString =
                    price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                      ? "Free for 7 days"
                      : new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: price.currency,
                          minimumFractionDigits: 0,
                        }).format((price?.unit_amount || 0) / 100);

                  return (
                    <div
                      key={product.id}
                      className={`flex flex-col rounded-lg shadow-sm divide-y divide-gray-600 bg-gray-800 ${
                        subscription
                          ? product.name ===
                            subscription?.prices?.products?.name
                            ? "border border-pink-500"
                            : ""
                          : product.name === "Freelancer"
                          ? "border border-pink-500"
                          : ""
                      } flex-1 basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/4 max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto`}
                    >
                      <div className="flex flex-col h-full p-6 w-64">
                        <h2 className="text-2xl font-semibold leading-6 text-white">
                          {product.name}
                        </h2>
                        <p className="mt-4 text-gray-300 flex-grow overflow-y-auto max-h-20">
                          {product.description}
                        </p>
                        <div className="mt-auto">
                          <p className="mt-8">
                            <span className="text-4xl font-extrabold text-gray-300">
                              {priceString}
                            </span>
                            <span className="text-base font-medium text-gray-100">
                              / month
                            </span>
                          </p>

                          <button
                            className="mt-5 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                            onClick={() =>
                              price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                                ? showPromiseConfirm(() =>
                                    handleTrialSubscription(price)
                                  )
                                : showPromiseConfirm(() =>
                                    handleStripeCheckout(price)
                                  )
                            }
                          >
                            {price.id === "price_1QTK38BZICJUgWJfR5u4pvsv"
                              ? "Start Free Trial"
                              : "Subscribe"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {!user ? (
              <div className="text-center mt-3">
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  onClick={() => navigate("/sign-in")}
                >
                  Sign In
                </button>
              </div>
            ) : (
              <div className="mt-3 text-center">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 mt-5"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            )}
          </div>
        </section>

        {showSnackbar && <Snackbar message={message} />}
      </>
    );
  }
}
