import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Headline from "../Headline/Headline";

const TermsAndConditions = () => {
  return (
    <div className="mt-14">
      <Header />
      <Headline text="Terms and conditions" />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h2 className="text-xl font-bold mt-6 mb-2">Article 1: Introduction</h2>
        <p className="mb-4">
          These Terms of Use (“Terms”) govern your use of swiftlead, a LinkedIn
          automation tool (“Tool”). By accessing or using the Tool, you agree to
          comply with these Terms. If you do not agree with these Terms, you
          must refrain from using the Tool. swiftlead reserves the right to
          modify these Terms at any time, and your continued use of the Tool
          after such modifications constitutes your acceptance of the updated
          Terms.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 2: Services Provided
        </h2>
        <p className="mb-4">
          swiftlead is a tool designed to streamline LinkedIn outreach efforts.
          By using swiftlead, users can gather relevant information to help them
          identify opportunities, engage with key contacts, and optimize their
          networking strategies. The tool assists both job seekers and companies
          in efficiently managing their outreach to relevant individuals,
          facilitating meaningful connections and improving overall engagement.
          swiftlead operates within the limits established by LinkedIn, adhering
          to their guidelines for permissible data access. Users are expected to
          respect these limits and engage with the platform’s publicly available
          information in accordance with LinkedIn’s terms.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 3: User Obligations
        </h2>
        <p className="mb-4">
          When using swiftlead, you agree to provide truthful, accurate,
          current, and complete information during your interactions. This
          includes any information gathered or used through swiftlead to support
          your outreach efforts. swiftlead welcomes feedback and suggestions to
          improve our services. Any feedback you provide becomes the exclusive
          intellectual property of swiftlead. We reserve the right to use,
          disclose, and modify any feedback for the enhancement of our services,
          without obligation to compensate or notify you.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 4: Prohibited Uses
        </h2>
        <p className="mb-4">
          You are prohibited from using swiftlead for any unlawful or
          unauthorized activities, including violating LinkedIn’s terms of
          service. You must refrain from engaging in any activity that may
          damage, disable, or overburden swiftlead’s functionality or interfere
          with other users’ access to the tool. You must also refrain from using
          swiftlead to engage in abusive activities such as sending unsolicited
          or spam messages or using the tool to misuse information or engage in
          activities not aligned with LinkedIn’s guidelines.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 5: Prohibition of Reverse Engineering and Competition
        </h2>
        <p className="mb-4">
          Users are strictly prohibited from attempting to reverse engineer,
          decompile, or disassemble swiftlead or any portion of its code. This
          includes any attempt to analyze, replicate, or modify the underlying
          functionality of the tool. All intellectual property rights related to
          swiftlead, including but not limited to copyrights, trademarks, and
          patents, remain the exclusive property of swiftlead. Users must not
          infringe upon or misappropriate these intellectual property rights.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 6: Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          swiftlead is provided on an “as is” and “as available” basis without
          any warranties, express or implied, including but not limited to
          warranties of merchantability, fitness for a particular purpose, or
          non-infringement. swiftlead does not guarantee the accuracy,
          reliability, or availability of the tool. swiftlead does not guarantee
          that the tool will meet your specific requirements or achieve any
          desired outcomes. The tool’s operation may be affected by a variety of
          factors, and swiftlead cannot guarantee error-free or uninterrupted
          use. Any material downloaded or obtained through the use of the tool
          is done at your own discretion and risk. You will be solely
          responsible for any damage to your computer system, mobile device, or
          data that results from the download or use of any such material.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 7: Limitation of Liability
        </h2>
        <p className="mb-4">
          In no event will swiftlead be liable for any indirect, incidental,
          special, consequential, or punitive damages, even if swiftlead was
          advised of the possibility of such damages. This includes damages for
          loss of profits, goodwill, use, data, or other intangible losses. This
          limitation applies whether the liability arises from contract, tort,
          negligence, or any other basis. In jurisdictions that do not allow the
          exclusion or limitation of certain damages, swiftlead’s liability will
          be limited to the maximum extent allowed by law. swiftlead’s total
          liability to you for all losses, damages, and causes of action will
          not exceed the amount you paid to access or use the tool.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 8: Modification of Terms
        </h2>
        <p className="mb-4">
          swiftlead reserves the unilateral right to modify, amend, or update
          these Terms of Use at any time and for any reason without prior notice
          to you. Such modifications will take effect immediately upon posting
          on the swiftlead website or direct communication to you via email or
          through the tool. It is your responsibility to periodically review
          these Terms to stay informed of updates. Your continued use of the
          tool after such modifications will constitute your acknowledgment of
          the modified Terms and agreement to abide and be bound by the updated
          Terms. Any changes to these Terms will apply immediately to new users
          and, following their posting or notification, to current users. If you
          do not agree with the modified Terms, you must discontinue using the
          swiftlead services immediately.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">Article 9: Termination</h2>
        <p className="mb-4">
          swiftlead may terminate your access to the tool and your account, at
          its sole discretion, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach the Terms. Upon
          termination, your right to use the tool will cease immediately.
          swiftlead reserves the right to delete all your data, information, and
          files stored on our servers associated with your use of the tool.
          swiftlead is not liable for any loss or damage following or arising
          from the termination of your account. You may terminate your account
          and discontinue your use of the tool at any time by following the
          account termination procedures made available through the tool, or by
          contacting swiftlead support directly. Notwithstanding termination,
          the provisions of these Terms that by their nature should survive
          termination shall remain in effect, including, but not limited to,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 10: Governing Law
        </h2>
        <p className="mb-4">
          These Terms and any action related thereto shall be governed by and
          construed in accordance with the laws of the State of Michigan, United
          States of America, without giving effect to any principles of
          conflicts of law. By using the tool, you agree that the laws of
          Michigan will govern these Terms and any dispute of any sort that
          might arise between you and swiftlead. Any legal suit, action, or
          proceeding arising out of, or related to, these Terms or the services
          provided by swiftlead shall be instituted exclusively in the federal
          courts of the United States or the courts of the State of Michigan.
          You waive any and all objections to the exercise of jurisdiction over
          you by such courts and to venue in such courts. We make no claims that
          the tool or any of its content is accessible or appropriate outside of
          the United States. Access to the tool may not be legal by certain
          persons or in certain countries. If you access the tool from outside
          the United States, you do so on your own initiative and are
          responsible for compliance with local laws.
        </p>
        <h2 className="text-xl font-bold mt-6 mb-2">
          Article 11: Contact Information
        </h2>
        <p className="mb-4">
          For any questions, concerns, or comments about these Terms, the
          services, or any other inquiries, please contact swiftlead via email
          at support@swiftlead.io. Our dedicated customer support team is
          available to assist you with any issues or questions you may have
          regarding your use of the tool. We strive to provide timely and
          supportive assistance to ensure your satisfaction with our services.
          <a
            href="mailto:support@swiftlead.io"
            className="text-blue-500 hover:underline"
          >
            support@swiftlead.io
          </a>
          .
        </p>
        <p className="mb-4">
          <strong>Customer Support</strong> Our dedicated customer support team
          is available to assist you with any issues or questions you may have
          regarding your use of the Tool. We strive to provide timely and
          supportive assistance to ensure your satisfaction with our services.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
