import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col items-center h-screen" data-theme="dark">
      <header className="w-full text-center p-4 text-2xl font-bold">
        Access Denied
      </header>

      <div className="p-6 max-w-sm w-full bg-white shadow-md rounded-md mt-[30vh] text-center">
          <p className="text-blue-500 text-center p-5">
            You must sign in to access this page.
          </p>

          <button
            type="submit"
            className="w-full p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#5273fd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => navigate('/sign-in')}
          >
            Go to Sign In
          </button>
      </div>
    </div>
  );
}
