import React from "react";

function Headline({ text }) {
  return (
    <div
      style={{
        background:
          "linear-gradient(to top, rgb(237, 242, 249) 0%, rgb(254, 255, 255) 40%)",
      }}
    >
      <div className="w-full h-[200px] font-bold flex flex-col justify-center items-center">
        <div className="text-2xl text-[#393857] md:text-4xl">{text}</div>
        <p className="mb-4">Effective Date: May 17, 2024</p>
      </div>
    </div>
  );
}

export default Headline;
